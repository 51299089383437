
 class IndexDB  {
    constructor(name,version,table) {
        this.name = name;
        this.version = version;
        this.table=table
        this.initStatus=0 //0 未初始化 ； 1：初始化中 ；2 :完成
    }
    
    async init(){
        this.initStatus=1
        this.db=await new Promise((resolve, reject) => {
            let vsaDb;
            let dbRequest = window.indexedDB.open(this.name, this.version);
            dbRequest.onsuccess =  (event)=> {
                vsaDb = dbRequest.result;
                console.log('数据库打开成功');
                this.initStatus=2
                resolve(vsaDb);
            };
            dbRequest.onupgradeneeded =  (event)=> {
                vsaDb = event.target.result;
                let objectStore;
                if (!vsaDb.objectStoreNames.contains(this.table)) {
                objectStore = vsaDb.createObjectStore(this.table, { keyPath: 'id' });
                }
                console.log('建表成功');
            };
        });
    };
    async insert(entity) {
        return new Promise((resolve, reject) => {
            let request = this.db.transaction([this.table], 'readwrite')
                .objectStore(this.table)
                .add(entity);
        
            request.onsuccess =  (event)=> {
                console.log('devtools-fetchInterceptor:数据写入成功');
                resolve();
            };
        
            request.onerror =  (event)=> {
                console.log('devtools-fetchInterceptor:数据写入失败');
                reject(-1);
            };
        });
    };
    async query(id){
        return new Promise((resolve, reject) => {
            let transaction = this.db.transaction([this.table]);
            let objectStore = transaction.objectStore(this.table);
            let request = objectStore.get(id);
        
            request.onerror = (event) => {
                console.log('事务失败');
                reject(-1);
            };
        
            request.onsuccess = (event) => {
                resolve(request.result);
            };
        });
    };
    async queryAll(){
        return new Promise((resolve, reject) => {
            let objectStore = this.db.transaction(this.table).objectStore(this.table);
            let results = [];
            objectStore.openCursor().onsuccess = (event) => {
                let cursor = event.target.result;
        
                if (cursor) {
                    results.push(cursor.value);
                    cursor.continue();
                } else {
                    console.log('没有更多数据了！');
                    resolve(results);
                }
            };
        });
    };
    async update(id,data){
        return new Promise((resolve, reject) => {
            let transaction = this.db.transaction([this.table], 'readwrite');
            let objectStore = transaction.objectStore(this.table);
            let request = objectStore.get(id);
        
            request.onerror = (event) => {
                console.log('事务失败');
                reject(-1);
            };
        
            request.onsuccess = (event) => {
                const entry=Object.assign(request.result,data)
                console.log(objectStore)
                const updateRequest = objectStore.put(entry);

                updateRequest.onsuccess = () => {
                    resolve(updateRequest.result);
        
                }
        
                // resolve(request.result);
            };
        });
    };
}

const test= async ()=>{
    const toolsDB= new IndexDB('vsa-db',1,'devtools')
    await toolsDB.init()
    const insertData={
        id:'/api/business/pro/pay/wallet/record',
        replace: false
    }
    const queryResult= await toolsDB.query(insertData.id)
    if(!queryResult){
        await toolsDB.insert({
            id:'/api/business/pro/pay/wallet/record',
            replace: false
        })
        
    }
   
    await toolsDB.update('/api/business/pro/pay/wallet/record',{
        replace: true
    })
    const data= await toolsDB.queryAll()
  
    console.log(data)
}
// test()


export default IndexDB