import "./style.css";
import { render, h } from 'preact';
import {start} from './fetchInterceptor'
import Panel from './panel.component';

start()
if (window.customElements) {
  window.customElements.define(
    'vsa-devtools',
    preactCustomElement(Panel)
  );
}
function preactCustomElement(Comp, observedAttributes = []) {
  return class PreactCustomElement extends HTMLElement {
    connectedCallback() {
      this.renderWithPreact();
    }
    disconnectedCallback() {
      render(null, this);
      this.renderedEl = null;
    }
    static get observedAttributes() {
      return observedAttributes;
    }
    attributeChangedCallback() {
      this.renderWithPreact();
    }
    renderWithPreact() {
      this.renderedEl = render(
        h(Comp, { customElement: this }),
        this,
        this.renderedEl
      );
    }
  };
}
