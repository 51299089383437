import { h, Component } from "preact";
import {getDB} from './fetchInterceptor'
const LS_VISIBLE_KEY = '__vsa_devtools_visible';


export default class Panel extends Component {
  state = {
    show: localStorage.getItem(LS_VISIBLE_KEY) === 'true',
    apiList: [],
  }
  async queryApiList(){
    const db= getDB()
      if(db.initStatus!==2){
        setTimeout(() => {
          this.queryApiList()
        }, 1000);
        return
      }
      console.log(db,db.initStatus)
      const list= await getDB().queryAll()
      if(list && list.length>0){
        this.setState({
          apiList:list
        })
      }

  }
  componentDidMount() {
  
    this.queryApiList()
  }
  onCheckboxChange(id,replace){
    getDB().update(id,{replace:!replace})
  }

  componentWillUnmount() {
    console.log('unmount');
  }
  
  render(props, state) {
    return (
      <div>
        <i className={`panel-trigger-btn ${state.show ? 'close' : ''}`} onClick={() => {
          
          const show = !state.show;
          if(show){
            this.queryApiList()
          }
          this.setState({
            show,
          });
          localStorage.setItem(LS_VISIBLE_KEY, show);
        }}></i>
        <div className="panel" style={{ display: state.show ? 'flex' : 'none' }}>
          <header className="panel-header">
            <h1>
              <i className="panel-title-hint">{'{'}</i>Vsa Devtools<i className="panel-title-hint">{'}'}</i>
            </h1>
          </header>
          <main className="panel-main">
            <ul>
              {
                state.apiList.map(({ id,replace }) => (
                  <li className="panel-item">
                    <h3 className="panel-item-name">
                    <input type="checkbox" onClick={()=>this.onCheckboxChange(id,replace)} checked={ replace }/></h3>
                    <span className="panel-item-url">{ id }</span>
                  </li>
                ))
              }
              </ul>
          </main>
          <footer className="panel-footer">
            <span className="panel-icon" onClick={() => {
              this.queryApiList()
            }}>V</span>
          </footer>
        </div>
      </div>
    )
  }
}
