import IndexDB  from "./indexDB";
var toolsDB=new IndexDB('vsa-db',1,'devtools')

export const getDB=()=>{
    return toolsDB
}
const getPath = (url, type)=>{
    if (url.search(`/${type}/`) === 0) {
      const at = url.search('\\?');
      let path = url;
      if (at !== -1) {
        path = path.substr(0, at);
      }
      return path;
    }
    return null;
};
const interceptor = {
   
    async fetchInterceptor(url, options) {
      console.log('devtools-fetchInterceptor:url', url);
     
      if(toolsDB.initStatus===0){
        await toolsDB.init()
      }
      if(toolsDB.initStatus!==2){
       
        return new  Promise(resolve=>{
          setTimeout(()=>{
            resolve(fetch(url, options))
          },500)
        })
      }

      // if(toolsDB.initStatus===2){
        const urlPath = getPath(url, 'api');
        if (urlPath) {
            const id = urlPath;
            const queryResult = await toolsDB.query(id);
            if (!queryResult) {
              await toolsDB.insert({
                id,
                replace: false,
              });
            } else if (queryResult.replace) {
              url = url.replace(/^\/api\//, '\/mock\/');
            }
          }
          console.log('devtools-fetchInterceptor:path', urlPath);
      // }
     
      const response = await vasOriginalFetch(url, options);
      return response;
    },
    
};
  
const vasOriginalFetch = window.fetch;
export const start=()=>{
    window.fetch = async (url, options) => {
        const interceptorMethod = interceptor.fetchInterceptor || ((url, options) => fetch(url, options));
        return interceptorMethod(url, options);
    };
}